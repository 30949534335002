import View from 'ln/view/View';

import LernFragen from 'lf/Lernfragen';
import ModelBasedDeco from 'lf/decorators/ModelBasedDecorator';
import RelatedFeedbackDeco from 'lf/decorators/RelatedFeedback';
import SlideModel from 'lf/slides/Slide/SlideModel';

import FeedbackButtonDeco from '../decorators/FeedbackButtonDecorator';
import EvaluateAnswersDeco from 'lf/decorators/EvaluateAnswers';
import ShowCorrect from 'lf/decorators/ShowCorrect';


class Quiz extends View {

    public model: SlideModel;
    public lernfragen: LernFragen;

	constructor( model: SlideModel ) {
		super();
        this.defaultTemplate = 'el.quiz-element';
        this.model = model;
    }
    
    protected  init() {
        
        this.lernfragen = new LernFragen().defaultSetup();
        
        //models are mapped by lernfragenbuch
        var slide = this.model;

        this.lernfragen.render( this.node.js('quiz') ) as LernFragen;
        
        var modelBasedDeco = new ModelBasedDeco();
        modelBasedDeco.default(
            [ 
                new RelatedFeedbackDeco({ posFeedbackProperty: 'posFeedback', negFeedbackProperty: 'negFeedback', posFeedback: 'Richtig', negFeedback: 'Leider falsch' }),
                new EvaluateAnswersDeco({}),
                new ShowCorrect()
            ]
        );
        // modelBasedDeco.set( 'App\\DropDown', 
        // [       new RelatedFeedbackDeco({ posFeedbackProperty: 'posFeedback', negFeedbackProperty: 'negFeedback' }),
        //         new EvaluateAnswersDeco({}),
        //         new ShowCorrect()
        //     ]
        // );

        // modelBasedDeco.set( 'App\\Hotspot', 
        // [       new RelatedFeedbackDeco({ posFeedbackProperty: 'posFeedback', negFeedbackProperty: 'negFeedback' }),
        //         new EvaluateAnswersDeco({}),
        //         new ShowCorrect()
        //     ]
        // );

        this.lernfragen.renderer.userAnswered.add(  modelBasedDeco.decorate.bind( modelBasedDeco )  );
        //this.lernfragen.renderer.userInput.add(  modelBasedDeco.decorate.bind( modelBasedDeco )  );
        //this.lernfragen.renderer.userInput.add(this.removeFeedback.bind(this));


        var modelBasedDeco = new ModelBasedDeco();
        modelBasedDeco.default(
            [ 
                new FeedbackButtonDeco(),
            ]
        );
        this.lernfragen.renderer.slideChanged.add( modelBasedDeco.decorate.bind( modelBasedDeco ) );
        this.lernfragen.renderer.show( slide );
    }
    
    protected renderData() {
        return this.model;
    }
    


    // protected removeFeedback() {
        // if (this.node.one('.feedback-panel') ) {
        //     this.node.one('.feedback-panel').remove();
        // }
        // if ( this.node.one('.dropdown') ) {
        //     this.node.all('.dropdown').forEach( (dd ) => {
        //         dd.removeClass('-correct');
        //         dd.removeClass('-wrong');;
        //     })
        // }
    // }


}

export default Quiz;

