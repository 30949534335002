
import Node from 'ln/node/Node';
import Request from 'ln/request/Request';
import View from 'ln/view/View';
import ListRenderer from 'ln/list/ListRenderer';
import ModifiableList from 'ln/list/ModifiableList';
import  Selection  from 'lnui/dropdown/Selection';
import { DataList,DataListConfig } from 'lnui/dropdown/DataList';

import Template from 'ln/template/TemplateManager';
import setup, { SetupConfig } from 'ln/setup/setup';
import { List } from 'ln/list/List';

import ElementModel from '../models/ElementModel';
import ElementRenderer from '../services/ElementRenderer';
import { taskeditor } from '../Main';


export class Autocomplete extends View{

    searchModel: string;
    searchAttribute: string;
    value: string;
    selectionTemplate: string;
    selectionItemTemplate: string;

    constructor( ){
        super( );
        this.selectionTemplate = 'hr.selection-template';
        this.selectionItemTemplate = 'hr.selection-item-template';

    }

    init() {
        super.init();
        this.searchModel = this.node.getAttribute('data-model');
        this.searchAttribute = this.node.getAttribute('data-attribute');
        let container = Node.fromHTML( Template.render(this.selectionTemplate) );
        this.node.append(container);
        this.node.one('.input').keyup.add( this.delay( this.onKeydown.bind(this), 500 ));
    }
    

    onKeydown(event) {
        
        if (event.value.length > 3 ) {
            
            setup.route('autocomplete').send( JSON.stringify( {modelName: this.searchModel, attribute: this.searchAttribute, value: event.value} ) )
            .then( function( result) {
                this.node.js('container').empty();
                this.node.addClass('-open');
               
                result.forEach( item => {
                    let current = Node.fromHTML(Template.render( this.selectionItemTemplate,  {name: item[0]}  ));
                    current.click.add( (current)=> {
                        this.node.js('container').empty();
                        this.node.one('.input').value = current.html;
                    })
                    this.node.js('container').append (current);
                });
            }.bind(this));
        }
    }

     delay(callback, ms) {
        var timer = 0;
        return function() {
          var context = this, args = arguments;
          clearTimeout(timer);
          timer = setTimeout(function () {
            callback.apply(context, args);
          }, ms || 0);
        };
      }

      
};


export default Autocomplete;