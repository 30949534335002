import View from '../../core/view/View';
import Node from '../../core/node/Node';
import CSSTransition from '../../core/animation/CSSTransition';
import { scanner } from '../../core/view/Scanner';

interface CollapseConfig {
	toggleNode?:string,
	contentNode?:string,
	duration?:number,
}

class Collapse extends View {

	data:CollapseConfig;

	constructor( config:CollapseConfig ) {
		if( !config.contentNode ) config.contentNode = 'content';
		if( !config.toggleNode ) config.toggleNode = 'toggle';

		super( config );
	}

	init() {
		this.toggle.click.add( this.onToggle.bind( this ) );
	}
	
	onToggle() {
		( this.node.hasClass( '-close' ) ) ? this.open() : this.close();
	}
	
	open() {
		this.node.removeClass( '-close' );
		var height = this.content.bounds().height;

		var transition = new CSSTransition( { target:this.content, cssClass: '-transition', duration:this.data.duration } );
		transition.start().then( () => {
			this.content.style.height = '';
		});
		this.content.style.height = height + 'px';
	}
	
	close() {
		this.content.style.height = this.content.bounds().height + 'px';
		var transition = new CSSTransition( { target:this.content, cssClass: '-transition', duration:this.data.duration } );
		transition.start().then( () => {
			this.node.addClass( '-close' );
		});
		this.content.style.height = '';
	}

	get toggle() {
		return this.node.js( this.data.toggleNode );
	}
	get content() {
		return this.node.js( this.data.contentNode );
	}
}

scanner.ioc.add( 'collapse', function( node:Node ) {
	return new Collapse( { contentNode:node.data.contentNode, toggleNode:node.data.toggleNode, duration:parseInt( node.data.duration ) } ).render( node );
});