import { DataList,DataListConfig } from './DataList';
import Node from '../../core/node/Node';
import Template from '../../core/template/TemplateManager';


class Selection<G> extends DataList<G> {

	public selected:G;

	constructor( config:DataListConfig ) {
		super( config );
		this.defaultTemplate = "ui.selection-dropdown";
	}

	init() {
		super.init();
		this.renderSelection();
		this.clicked.add( this.select.bind( this ) );
	}

	/**
	 * Renders the current selection.
	 */
	protected renderSelection() {
		var node = ( this.selected ) ? this.listRenderer.ioc.get( 'default' )( this.selected ).node : Node.fromHTML( Template.render( 'ui.selection-dropdown-empty' ) );
		this.node.js( 'selection' ).html = node.html;
	}

	/**
	 * Selects the given data in the selection dropdown
	 * @param data 
	 */
	select( data:G ) {
		this.selected = data;
		this.renderSelection();
	}

	/**
	 * Clears the selection in the dropdown
	 */
	clear() {
		this.selected = null;
		this.renderSelection();
	}
}

export default Selection;