import List from 'ln/list/List';
import ListRenderer from 'ln/list/ListRenderer';
import Node from 'ln/node/Node';

import ElementModel from '../models/ElementModel';
import elementsIoC from '../setup/elements';


class ElementRenderer {
    target: Node
    
    constructor( target ) {
        this.target = target;
    }

    render(elements) {
        var renderer = new ListRenderer<ElementModel>( this.target );
        renderer.ioc = elementsIoC;
        renderer.selectorFunction = element => element.modelName;
        renderer.source = new List<ElementModel>(elements);
    }
}


export default ElementRenderer;
