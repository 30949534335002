import Mapper from 'ln/model/Mapper';
import SingleChoiceModel from 'lf/slides/SingleChoice/SingleChoiceModel';
import MultipleChoiceModel from 'lf/slides/MultipleChoice/MultipleChoiceModel';
import DropDownModel from 'lf/slides/DropDown/DropDownModel';
import HotSpotModel from 'lf/slides/HotSpot/HotSpotModel';
import ElementModel from '../models/ElementModel';



var mapper = new Mapper();

mapper.toModel.add( 'App\\Hotspot', function ( json ) {
    return new HotSpotModel( json );
});

mapper.toModel.add( 'App\\DropDown', function ( json ) {
    return new DropDownModel( json );
});

mapper.toModel.add( 'App\\MultipleChoice', function ( json ) {
    return new MultipleChoiceModel( json );
});

mapper.toModel.add( 'App\\SingleChoice', function ( json ) {
    return new SingleChoiceModel( json );
});
//dont map
mapper.toModel.add( 'App\\ChoiceAnswer', function ( json ) {
    return json;
});


mapper.toModel.add( 'default', function ( json ) {
    //console.info(`Model of type ${json.modelName} is being mapped to 'ElementModel'.`);
    return new ElementModel( json );
    
});

export default mapper;

