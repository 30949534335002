
import Node from 'ln/node/Node';
import Request from 'ln/request/Request';
import View from 'ln/view/View';
import Template from 'ln/template/TemplateManager';
import setup, { SetupConfig } from 'ln/setup/setup';

import ElementModel from '../models/ElementModel';
import ElementRenderer from '../services/ElementRenderer';


export class UnitPage extends View {
    model:ElementModel;

    constructor( model ){
        super( model );
        this.defaultTemplate = 'hr.unit-page';
        this.model = model;
    }

    init(){
        if (this.model.introAsset[0]) {
            var introRenderer = new ElementRenderer( this.node.js('intro-element'));
            introRenderer.render( [this.model.introAsset[0]]); //Render only the first Element...
        }
        
        var renderer = new ElementRenderer( this.node.js('unit-elements') );
        renderer.render( this.model.contentElementsStudent );

        //teacher comment
        if ( !this.model.isStudent ){
            var renderer = new ElementRenderer( this.node.js('teacher-description') );
            renderer.render( this.model.teacherDescription );
        }

    }



}


export default UnitPage;