
import Node from 'ln/node/Node';
import Request from 'ln/request/Request';
import View from 'ln/view/View';
import ListRenderer from 'ln/list/ListRenderer';
import ModifiableList from 'ln/list/ModifiableList';

import Template from 'ln/template/TemplateManager';
import setup, { SetupConfig } from 'ln/setup/setup';
import { List } from 'ln/list/List';


import ElementModel from '../models/ElementModel';
import ElementRenderer from '../services/ElementRenderer';
import MagazinePage from './MagazinePage';


import * as clipboard  from "clipboard-polyfill/clipboard-polyfill";
//import * as clipboard from "clipboard-polyfill/build/clipboard-polyfill.promise"


export class TaskEditorPage extends MagazinePage {

    selectedTasks:[];
    taskTemplate: string;

    constructor( model ){
        super( model );
        this.taskTemplate = 'hr.task-container';
        this.selectedTasks = [];
        
    }

    init(){
        
        this.renderUnits();
        this.renderTeacher();
        var selectedTasks = (this.model.selectedTaskList) ? this.model.selectedTaskList : '';
        
        var taskContainer = Node.fromHTML( Template.render( this.taskTemplate, selectedTasks ) );

        this.node.js('task-container').insert(taskContainer);
        this.node.js('task-menu').addClass('-editor');

        this.node.js('share-task').click.add( this.shareTask, this);

        this.node.js('close-task').click.add( ()=> {
            window.location.href = setup.route('magazine', {shortCode: this.model.shortCode}).url();
        });

        //set selected tasks if we have a taskId
         if (this.model.selectedTaskList) {
            this.listRenderer.links.items.forEach( (link)=>{
                if ( this.model.selectedTaskList.shortCodes.indexOf( link.item.shortCode ) > -1) {
                    link.node.one('.unit-preview').addClass('-selected');
                    this.selectedTasks.push(link.item);
                }
            });
         }
    }

    //called when filter changes, resets the selected tasks
    setSelectedTasks(){
        this.updateFilter();
        if (this.selectedTasks) {
            this.listRenderer.links.items.forEach( (link)=>{
                this.selectedTasks.map(task => {
                    if (task.shortCode === link.item.shortCode){
                        link.node.one('.unit-preview').addClass('-selected');
                    }
                })
            });
         }
    }




    public shareTask() {
        var shortCodes = [];
        this.selectedTasks.forEach( ( task )=>{
            shortCodes.push( task.shortCode)
        });
        var tasks = {
            shortCodes: shortCodes,
            title: this.node.js('task-title').value,
            description: this.node.js('task-description').value,
            taskId: this.node.js('task-id').value,
            magazineShortCode: this.model.shortCode,
        }
        var isUpdate = this.node.js('task-id').value ? true : false
         //Request.post( setup.route('postTaskList').url()).headers( { 'Content-Type': 'application/json' } )
         
         setup.route('postTaskList').method('POST').send( JSON.stringify( tasks ) ).then( function(id) {
                var responseNode = Node.fromHTML( Template.render( 'hr.task-response', {shortCode: this.model.shortCode, taskId: id, isUpdate: isUpdate} ));
                
                responseNode.js('close-task-response').click.add( () => {
                    responseNode.empty();
                    window.location.href = setup.route('magazine', {shortCode: this.model.shortCode}).url();
                });

                responseNode.all('.copy').forEach( (link)=> {
                    link.click.add( (item) => {
                        let content = item.getAttribute('data-link');
                        this.copyElement( content )
                        window.scrollTo(0, 0);
                    } )
                });
                //set the hidden taskId, so an update doesnt create a new task
                this.node.js('task-id').value = id;
                this.node.js('task-response').replace(responseNode);
                window.scrollTo(0, 0);
                this.node.js('share-task').remove();
            }.bind(this));
    }
/*
    iosCopyToClipboard(el) {
        var oldContentEditable = el.contentEditable,
            oldReadOnly = el.readOnly,
            range = document.createRange();
    
        el.contentEditable = true;
        el.readOnly = false;
        range.selectNodeContents(el);
    
        var s = window.getSelection();
        s.removeAllRanges();
        s.addRange(range);
    
        el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.
    
        el.contentEditable = oldContentEditable;
        el.readOnly = oldReadOnly;
        document.execCommand('copy');
        
    }
     copyTextToClipboard(text) {
        // let doc = document.documentElement;
        // let top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

        var textArea = document.createElement("input");
        textArea.value = text;
        textArea.style.position = "fixed";
        textArea.style.bottom= 0;
        textArea.style.left= 0;

        document.body.appendChild(textArea)


        var isiOSDevice = navigator.userAgent.match(/ipad|iphone/i);
        if (isiOSDevice) {
            this.iosCopyToClipboard( textArea );
            // document.body.removeChild(textArea);
            return;
        }
        else {
            textArea.focus();
            textArea.select();
            var successful = document.execCommand('copy');
            
            var msg = successful ? 'Sie haben den Link in die Zwischenablage kopiert' : 'Leider können sie mit ihrem Browser den Link nicht automatisch in die Zwischenablage kopieren';
            let copyLinkOverlay = Node.fromHTML( Template.render( 'hr.task-alert', {message: msg } ));
              
              copyLinkOverlay.js('ok').click.add( ()=> {
                this.node.js('task-response').js('alert').remove();
              })
    
              this.node.js('task-response').append(copyLinkOverlay);

            document.body.removeChild(textArea);
            window.scrollTo(0, 0);
            //window.scrollTo(0, top);
        }


    }
*/

    public  copyElement( content ) {
        
        let result = clipboard.writeText(content);
        let msg = '';

        var isiOSDevice = navigator.userAgent.match(/ipad|iphone/i);
        if (isiOSDevice) {
            msg = 'Ihr Betriebssystem unterstützt leider kein automatisches Kopieren in die Zwischenablage. Sie haben die Informationen aber per Mail erhalten';
        }
        else {
            msg = 'Der Link wurde in Ihre Zwischenablage kopiert ';
        }

        let copyLinkOverlay = Node.fromHTML( Template.render( 'hr.task-alert', {message: msg } ));
        copyLinkOverlay.js('ok').click.add( ()=> {
            this.node.js('task-response').js('alert').remove();
        })
        this.node.js('task-response').append(copyLinkOverlay);
        window.scrollTo(0, 0);
    }

    //overwrite parent
    public onUnitClick( current ){
        current.one('.unit-preview').toggleClass( '-selected');
        var currentLink = this.that.listRenderer.links.find( function ( item){
            return item.node === current;
        });
        //add selected model
        if (current.one('.unit-preview').hasClass('-selected') && this.that.selectedTasks.indexOf(currentLink.item) == -1){
            this.that.selectedTasks.push(currentLink.item);
        }
        //remove selected model
        else if (!current.one('.unit-preview').hasClass('-selected') && this.that.selectedTasks.indexOf(currentLink.item) > -1) {
            this.that.selectedTasks.splice(this.that.selectedTasks.indexOf(currentLink.item), 1);
        }
    }
}

export default TaskEditorPage;