import View from 'ln/view/View';
import Template from 'ln/template/TemplateManager';

import List from 'ln/list/List';
import ListRenderer from 'ln/list/ListRenderer';
import Node from 'ln/node/Node';

import ElementModel from '../models/ElementModel';
import CommonContentElement from './CommonContentElement';

class TestimonialContainer extends CommonContentElement {
    model: ElementModel
    testimonialrenderer: ListRenderer<[]>;
    pointrenderer: ListRenderer<[]>;
    currentIndex: number;
    

    constructor(model: ElementModel) {
        super(model);
        this.currentIndex = 0;
    }

    public init() {
        this.renderTestimonials();
        this.showCurrent(0);
        this.startTimer(5000);
    }

    renderTestimonials() {
        
        this.testimonialrenderer = new ListRenderer<[]>( this.node.js('testimonials') );
        this.testimonialrenderer.defaultRender( model => {
            var item = Node.fromHTML( Template.render( 'el.testimonialcontainer-item', model ) );
            item.addClass('-hidden');
            return {node: item};
        });
        
        this.testimonialrenderer.source = new List<[]>(this.model.elements);
        this.pointrenderer = new ListRenderer<[]>( this.node.js('navigation') );
        this.pointrenderer.defaultRender( model => {
            var item = Node.fromHTML( '<div class="point"></div>' );
            item.click.add( (item)=> {
                clearInterval(this.interval);
                let index = this.testimonialrenderer.source.index(model);
                this.showCurrent( index );
            });
            return {node: item};
        });
        
        this.pointrenderer.source = new List<[]>(this.model.elements);

    }


    startTimer( delay: number) {
        this.interval = setInterval( ()=>{
            this.showCurrent( this.currentIndex)
            this.currentIndex += 1;
          }, delay);
    }


    showCurrent( index:number ) {
        if (index >= this.testimonialrenderer.links.length) {
            index = 0;
        }
        this.currentIndex = index;

        this.pointrenderer.links.all().forEach( link => {
            link.node.removeClass('-active');
        })
        this.testimonialrenderer.links.all().forEach( link => {
            link.node.addClass('-hidden');
        })

        let newTestimonial =  this.testimonialrenderer.linkOf( this.testimonialrenderer.source.get(this.currentIndex) );
        newTestimonial.node.removeClass('-hidden');

        let newPoint =  this.pointrenderer.linkOf( this.pointrenderer.source.get(this.currentIndex) );
        newPoint.node.addClass('-active');
    }

    renderData() {
        return this.model.object();
    }
}


export default TestimonialContainer;
