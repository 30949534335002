
import Node from 'ln/node/Node';
import Request from 'ln/request/Request';
import View from 'ln/view/View';
import Template from 'ln/template/TemplateManager';


import ElementModel from '../models/ElementModel';
import ElementRenderer from '../services/ElementRenderer';


export class SinglePage extends View {
    model:ElementModel;

    constructor( model ){
        super( model );
        this.defaultTemplate = 'hr.single-page';
        this.model = model;
    }

    init(){
        var renderer = new ElementRenderer( this.node.js('elements') );
        renderer.render( this.model.elements );
    }

    protected renderData() {
		return this.model;
	}

}


export default SinglePage;