import View from 'ln/view/View';

import ElementModel from '../models/ElementModel';


class CommonContentElement extends View {
    model: ElementModel

    constructor(model: ElementModel) {
        super(model);
        this.model = model;
        this.defaultTemplate = this.model ? this.model.get('template', 'el.' + this.model.modelName.toLowerCase().replace('app\\', '') + '-element') : '';
    }

    public init() {
    }

    renderData() {
        return this.model.object();
    }
}


export default CommonContentElement;
