import { ListRenderer, ILink } from '../../core/list/ListRenderer';
import List from '../../core/list/ModifiableList';
import View from '../../core/view/View';
import Node from '../../core/node/Node';
import Signal from '../../core/signal/Signal';
import Template from '../../core/template/TemplateManager';
import { DropDown, DropDownConfig } from './DropDown';

export interface SelectListener<G> {
	( data:G );
}

export interface DataListConfig extends DropDownConfig {
	listTemplate?:string,
}

export class DataList<G> extends DropDown {

	protected listRenderer:ListRenderer<G,List<G>>;
	protected config:DataListConfig;
	
	public clicked:Signal<SelectListener<G>> = new Signal<SelectListener<G>>();

	constructor( config:DataListConfig = {} ) {
		
		super( config );
		
		if( !config.listTemplate ) config.listTemplate = "[[ opt ]]";

		this.listRenderer = new ListRenderer<G,List<G>>();
		this.listRenderer.defaultRender( this.renderItem.bind( this ) );
	}

	init() {
		super.init();
		this.listRenderer.container = this.node.js( 'list' );
	}

	get source() {
		return this.listRenderer.source;
	}

	renderItem( data:G ) {
		var node = Node.fromHTML( Template.render( this.config.listTemplate, data ) );
		node.click.add( this.onClick, this );
		return { node:node };
	}

	protected onClick( node:Node, event:Event ) {
		this.clicked.dispatch( this.listRenderer.linkOf( node ).item );
	}
}

export default DataList;