import IoC from 'ln/ioc/IoC';

import CommonContentElement from '../elements/CommonContentElement';
import Quiz from '../views/Quiz';

import TestimonialContainer from '../elements/TestimonialContainer';
import MagazineOverviewElement from '../elements/MagazineOverviewElement';
// import CommonElementList from '../views/CommonElementList';
// import ExamModule from '../views/ExamModule';
// import Foldable from '../views/Foldable';
// import LernVideo from '../views/LernVideo';
// import ProgressModule from '../views/ProgressModule';
// import Tooltip from '../views/Tooltip';


var elementsIoC = new IoC<any>();

// elementsIoC.add( 'App\\LernVideo', function( data ) {
// 	return new LernVideo(  data  ).render();
// });

// elementsIoC.add( 'App\\ProgressModule', function( data ) {
// 	return new ProgressModule(  data  ).render();
// });
// elementsIoC.add( 'App\\ExamModule', function( data ) {
// 	return new ExamModule(  data  ).render();
// });


// elementsIoC.add( 'App\\Foldable', function( data ) {
// 	return new Foldable( data ).render();
// });

// elementsIoC.add( 'App\\SubNavigation', function( data ) {
// 	return new CommonElementList(  data  ).render();
// });

// elementsIoC.add( 'App\\CompositeArticle', function( data ) {
// 	return new CommonElementList(  data  ).render();
// });

// elementsIoC.add( 'App\\CompositeMaterial', function( data ) {
// 	return new CommonElementList(  data  ).render();
// });

// //original Lernfragen Slides
elementsIoC.add( 'App\\MultipleChoice', function( mc ) {
	var slide = new Quiz(  mc ).render();
	return slide;
});


// elementsIoC.add( 'App\\DragDrop', function( sc ) {
// 	var slide = new Quiz(  sc  ).render();
// 	return slide;
// });
elementsIoC.add( 'App\\DropDown', function( sc ) {
	var slide = new Quiz(  sc  ).render();
	return slide;
});
// elementsIoC.add( 'App\\FreeText', function( sc ) {
// 	var slide = new Quiz(  sc  ).render();
// 	return slide;
// });
elementsIoC.add('App\\Hotspot', function(sc) {
	var slide = new Quiz(sc).render();
	return slide;
});
// elementsIoC.add('App\\Tooltip', function(sc) {
// 	var slide = new Tooltip(sc).render();
// 	return slide;
// });

elementsIoC.add( 'App\\SingleChoice', function( sc ) {
	var slide = new Quiz(  sc  ).render();
	return slide;
});

elementsIoC.add( 'App\\TestimonialContainer', function( sc ) {
	return new TestimonialContainer(  sc  ).render();
});

elementsIoC.add( 'App\\MagazineOverview', function( sc ) {
	return new MagazineOverviewElement(  sc  ).render();
});



elementsIoC.add( 'default', function( data ) {
	//console.error(`No renderer configured for model of type '${data.modelName}'.`, {data});
	return new CommonContentElement( data ).render();
});


export default elementsIoC;

