
import Node from 'ln/node/Node';
import Request from 'ln/request/Request';
import View from 'ln/view/View';
import ListRenderer from 'ln/list/ListRenderer';
import ModifiableList from 'ln/list/ModifiableList';
import  Selection  from 'lnui/dropdown/Selection';

import Template from 'ln/template/TemplateManager';
import setup, { SetupConfig } from 'ln/setup/setup';
import { List } from 'ln/list/List';

import ElementModel from '../models/ElementModel';
import ElementRenderer from '../services/ElementRenderer';



export class StartPage extends View {
    model:ElementModel;

    constructor( model ){
        super();
        this.defaultTemplate = 'hr.start-page';
        this.model = model;
    }

    init(){

        
        var renderer = new ElementRenderer( this.node.js('elements') );
        renderer.render( this.model.elements );
    }

    protected renderData() {
		return this.model;
    }

}


export default StartPage;