
import Node from 'ln/node/Node';
import Request from 'ln/request/Request';
import View from 'ln/view/View';
import ListRenderer from 'ln/list/ListRenderer';
import ModifiableList from 'ln/list/ModifiableList';
import  Selection  from 'lnui/dropdown/Selection';

import Template from 'ln/template/TemplateManager';
import setup, { SetupConfig } from 'ln/setup/setup';
import { List } from 'ln/list/List';

import ElementModel from '../models/ElementModel';
import ElementRenderer from '../services/ElementRenderer';
import { taskeditor } from '../Main';


export class MagazinePage extends View {
    model:ElementModel;
    unitList:ModifiableList<[]>;
    listRenderer:ListRenderer<[]>;
    selectedLevelFilter:string;
    selectionItemTemplate: string;
    selectionTemplate: string;
    dropdown: Selection<{}>;

    role:string;

    constructor( model ){
        super();
        this.defaultTemplate = 'hr.magazine-page';
        this.selectionItemTemplate = 'hr.dropdown-selection-item';
        this.selectionTemplate = 'hr.selection-dropdown';
        

        this.model = model;
        //remove unpublished units
        this.model.units = this.model.units.filter( ( unit )=> {
            return unit.published;
        });

        this.selectedLevelFilter = 'Alle';
        this.role = 'teacher';
    }

    init(){


        //currently, this page is re-used for task-view (teacher & student). 
        //teacher-stuff is disabled by css
        if (this.model.task) {
            this.node.addClass('-student');
            this.role = 'student';
            //only show selected units in tasklist
            this.model.units = this.model.units.filter( ( unit )=> {
                return (this.model.task.shortCodes.indexOf(unit.shortCode)> -1);
            });

            //add studentTitle and Description from TaskList
            var studentNode = Node.fromHTML( Template.render( 'hr.student-task-comment', {title: this.model.task.title, description: this.model.task.description } ) );
            this.node.js('student-task-comment').append(studentNode);
        }
        
        else {
            //this.renderTeacher();
            
            //only authenticated user can create TaskLists
            if (this.model.userPermission) {
                this.node.js('add-task').click.add( (node)=> {
                        window.location.href = setup.route('taskeditor', {shortCode: this.model.shortCode}).url();
                });
            }
            else {
                this.node.js('add-task').remove();
                // this.node.js('add-task').addClass('-disabled');
            }
        }
        this.renderUnits();
    }

    public renderTeacher(){
        var renderer = new ElementRenderer( this.node.js('teacher-elements') );
        renderer.render( this.model.teacherDescription );
    }

    public renderUnits(){

        this.unitList = new ModifiableList( this.model.units );
        this.listRenderer = new ListRenderer( this.node.js('unit-container'));
        this.listRenderer.defaultRender( model  => {
            var node = Node.fromHTML( Template.render( 'hr.preview-unit', {model:model, role:this.role, isAuthenticated: this.model.isAuthenticated } ) );
            
            if (model.unitPermission || this.role == 'student' ) {
                node.click.add( this.onUnitClick, {model: model, that:this});
                // node.click.add( ()=> {
                //      window.location.href = (this.role == 'teacher') ? setup.route('unit', {shortCode: model.shortCode}).url() : setup.route('student-unit', {shortCode: model.shortCode}).url();
                // });
            }
            // else {
            //     node.click.add( ()=> {
            //         window.location.href = setup.route('login').url();
            //     });
            // }
            
            if (node.js('download')) {
                node.js('download').click.add( (item, event)=> {
                    event.stopPropagation();
                });
            }
            return {node: node};
        });
        
        this.listRenderer.source = this.unitList;

        //filter nuggets aka units
        var levelList = new List(['Alle', '1','2','3','4']);
        var levelFilterRenderer = new ListRenderer( this.node.js('level-filter'));
        levelFilterRenderer.defaultRender(levelNumber => {
            var node = Node.fromHTML( Template.render( 'hr.level-filter', {levelNumber: levelNumber} ) );
            node.click.add( (filter)=>{
                this.node.all('.filter').forEach( (filter)=> {
                    filter.removeClass('-active');
                })
                filter.toggleClass('-active');
                this.selectedLevelFilter = levelNumber as string;
                this.unitList.update();

                this.setSelectedTasks();
            })
            return {node: node}
        });
        levelFilterRenderer.source = levelList;
        this.unitList.modifiers.append( this.filterLevel.bind(this));

        //dropdown-filter
        let item = { values : [] };
        item.values.push(
            {text:  'ALLE', value: 'Alle', },
            {text:  'NIVEAU 1', value: '1', },
            {text:  'NIVEAU 2', value: '2', },
            {text:  'NIVEAU 3', value: '3', },
            {text:  'NIVEAU 4', value: '4', },
        );

        let dd = this.getDropDownFilterNode( item );
        this.node.js('dropdown-filter').append(dd);
        
        //sync active filter on resize
        window.addEventListener("resize", this.resizeThrottler.bind(this), false);

    }

    //overwrite in TaskEditorPage
    setSelectedTasks(){
        return;
    }


    getDropDownFilterNode( item ) {
        var dropdownContainer = Node.fromHTML(Template.render( this.selectionTemplate,  item  ));
        this.dropdown = new Selection( { listTemplate: this.selectionItemTemplate} ).render( dropdownContainer ) as Selection<string>;
        this.dropdown.source.fill(  item.values );

        this.dropdown.clicked.add( ( selection )=> {
            this.selectedLevelFilter = selection.value;
            this.unitList.update();

            this.setSelectedTasks();
        })

        item.values.forEach( (value) => {
            if( value.value === 'Alle') {
                this.selectedLevelFilter = value.value;
                this.dropdown.select(value)
            }
        });

        return dropdownContainer;
    }

    public onUnitClick( current ) {
        window.location.href = (this.that.role == 'teacher') ? setup.route('unit', {shortCode: this.model.shortCode}).url() : setup.route('student-unit', {shortCode: this.model.shortCode}).url();
    }

    private filterLevel( models ){

        if (this.selectedLevelFilter === 'Alle') {
            return models;
        }
        return models.filter( (model) => {
            return model.level.indexOf(this.selectedLevelFilter ) > -1 ;
        });
    }

    protected renderData() {
		return this.model;
    }

    resizeThrottler() {
        var resizeTimeout;
        // ignore resize events as long as an resizeHandler execution is in the queue
        if ( !resizeTimeout ) {
          resizeTimeout = setTimeout(function() {
            resizeTimeout = null;
            this.updateFilter();
         
           // The actualResizeHandler will execute at a rate of 15fps
           }.bind(this), 66);
        }
      }

      updateFilter(){
        let selected = this.dropdown.source.items.filter( (item) => {
            return item.value === this.selectedLevelFilter;
        });
        this.dropdown.select(selected[0]);

        this.node.all('.filter').forEach( (filter)=> {
            filter.removeClass('-active');
            
            if (filter.one('.item').getAttribute('data-value') == this.selectedLevelFilter) {
                filter.addClass('-active');
            }
        })
      }


}


export default MagazinePage;