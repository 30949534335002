import View from 'ln/view/View';
import Template from 'ln/template/TemplateManager';

import List from 'ln/list/List';
import LinkedList from 'ln/linkedlist/LinkedList';

import ListRenderer from 'ln/list/ListRenderer';
import Node from 'ln/node/Node';

import ElementModel from '../models/ElementModel';
import CommonContentElement from './CommonContentElement';

class MagazineOverviewElement extends CommonContentElement {
    model: ElementModel
    contentrenderer: ListRenderer<[]>;
    pointrenderer: ListRenderer<[]>;
    currentIndex: number;
    
    
    constructor(model: ElementModel) {
        super(model);
        this.currentIndex = 0;
    }

    public init() {
                
        this.contentrenderer = new ListRenderer<[]>( this.node.js('elements') );
        this.contentrenderer.defaultRender( model => {
            var item = Node.fromHTML( Template.render( 'el.magazine-preview', model ) );
            // item.addClass('hidden');
            return {node: item};
        });

        this.contentrenderer.source = new List<[]>(this.model.elements);

        this.pointrenderer = new ListRenderer<[]>( this.node.js('navigation-points') );
        this.pointrenderer.defaultRender( model => {
            var item = Node.fromHTML( '<div class="point"></div>' );
            item.click.add( (item)=> {
                let index = this.contentrenderer.source.index(model);
                this.showCurrent( index );
            });
            return {node: item};
        });
        
        this.pointrenderer.source = new List<[]>(this.model.elements);

        this.registerNavigation();
        window.addEventListener("resize", this.resizeThrottler.bind(this), false);
        this.resizeHandler();
    }

    registerNavigation() {
        this.node.js('previous').click.add(this.showPrevious, this);
        this.node.js('next').click.add(this.showNext, this);
    }

    showNext() {
        this.showCurrent( this.currentIndex + 1);
    }
    
    showPrevious() {
        this.showCurrent(this.currentIndex - 1);
    }

    showCurrent( index:number ) {
        if (index >= this.contentrenderer.links.length) {
            index = 0;
        }
        else if (index < 0) {
            index = this.contentrenderer.links.length -1;
        }
        this.currentIndex = index;

        this.pointrenderer.links.all().forEach( link => {
            link.node.removeClass('-active');
        })
        this.contentrenderer.links.all().forEach( link => {
            link.node.addClass('-hidden');
        })

        let content =  this.contentrenderer.linkOf( this.contentrenderer.source.get(this.currentIndex) );
        content.node.removeClass('-hidden');

        let newPoint =  this.pointrenderer.linkOf( this.pointrenderer.source.get(this.currentIndex) );
        newPoint.node.addClass('-active');
    }

    renderData() {
        return this.model.object();
    }


    resizeThrottler() {
        var resizeTimeout;
        // ignore resize events as long as an resizeHandler execution is in the queue
        if ( !resizeTimeout ) {
          resizeTimeout = setTimeout(function() {
            resizeTimeout = null;
            this.resizeHandler();
         
           // The actualResizeHandler will execute at a rate of 15fps
           }.bind(this), 66);
        }
      }

      resizeHandler(){
        let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (width < 980 ) {
            this.node.all('.magazine-preview').forEach( (item, index) => {
                if (index != this.currentIndex ) {
                    item.addClass('-hidden');
                }
            });
            this.showCurrent(this.currentIndex);
        }
        else {
            this.node.all('.magazine-preview').forEach(item => {
                item.removeClass('-hidden');
            });
        }
    }


}

export default MagazineOverviewElement;