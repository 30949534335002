import View from '../../core/view/View';
import { scanner } from '../../core/view/Scanner';
import Node from '../../core/node/Node';

export interface DropDownConfig {
	openClass?:string,
	autoPlace?:boolean,
}

export class DropDown extends View {

	private documentHandler:( event ) => void;
	protected config:DropDownConfig;

	constructor( config:DropDownConfig = {} ) {
		super();

		if( !config.openClass ) config.openClass = "-open";
		if( !config.autoPlace ) config.autoPlace = false;

		this.config = config;

		this.documentHandler = ( event ) => {
			this.close();
		}
	}

	init() {
		this.node.js( "toggle" ).click.add( this.toggle, this );

		// check if content node extists to prevent close.
		var contentNode = this.node.js( "content" );
		if( contentNode ) {
			contentNode.click.add( ( node, event ) => {
				// do not propagate event to prevent close in content click
				event.stopImmediatePropagation();
			});
		}
	}

	open() {
		if( this.config.autoPlace ) this.autoShow();
		this.node.addClass( this.config.openClass );
		document.addEventListener( 'click', this.documentHandler.bind( this ) );
	}

	close() {
		this.node.removeClass( this.config.openClass );
		document.removeEventListener( 'click', this.documentHandler.bind( this ) );
	}

	toggle( node:Node, event:Event ) {
		event.stopImmediatePropagation();
		( this.node.hasClass( '-open' ) ) ? this.close() : this.open();
	}

	autoShow() {
		var bounds = this.node.bounds( true );
		var vw = document.documentElement.clientWidth;
		var vh = document.documentElement.clientHeight;

		this.node.toggleClass( '-left', bounds.left < vw - bounds.right );
		this.node.toggleClass( '-right', bounds.left > vw - bounds.right );
		this.node.toggleClass( '-top', bounds.top > vh - bounds.bottom );
		this.node.toggleClass( '-bottom',  bounds.top < vh - bounds.bottom );
	}
}

export default DropDown;

scanner.ioc.add( 'drop-down', function( node ) {
	return new DropDown( { openClass: node.data.openClass, autoPlace:node.data.autoPlace != undefined } ).render( node );
});