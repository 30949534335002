
import Node from 'ln/node/Node';
import Request from 'ln/request/Request';
import View from 'ln/view/View';
import ListRenderer from 'ln/list/ListRenderer';
import ModifiableList from 'ln/list/ModifiableList';
import  Selection  from 'lnui/dropdown/Selection';

import Template from 'ln/template/TemplateManager';
import setup, { SetupConfig } from 'ln/setup/setup';
import { List } from 'ln/list/List';

import ElementModel from '../models/ElementModel';
import ElementRenderer from '../services/ElementRenderer';
import { taskeditor } from '../Main';


export class PreviewPage extends View {
    model:ElementModel;
    role:string;

    constructor( model ){
        super();
        this.defaultTemplate = 'hr.preview-page';
        this.model = model;
    }

    init(){
        this.renderContents();
        var renderer = new ElementRenderer( this.node.js('preview-overview') );
        let elements = [];
        elements.push( this.model.overview)
        renderer.render( elements );

    }

    public renderContents(){
        let contentsList = new List( this.model.contents );
        let listRenderer = new ListRenderer( this.node.js('contents-table'));
        listRenderer.defaultRender( model  => {
            var node = Node.fromHTML( Template.render( 'hr.contents-element', model ) );
            return {node: node};
        });
        listRenderer.source = contentsList;
    }

    protected renderData() {
		return this.model;
    }
}


export default PreviewPage;