import Model from 'ln/model/Model';


class ElementModel extends Model {


    constructor(json: Object) {
        super(json);
    }

    get id(): number {
        return this.get<number>('id');
    }

    get modelName(): string {
        return this.get<string>('modelName');
    }



    object() {
        var obj: any = super.object();
        if (!obj.uid) obj.uid = this.uid;
        return obj;
    }
}

export default ElementModel;