import "es6-promise/auto";
import { Node } from "../node/Node";

interface CSSTransitionConfig {
	target:Node,
	cssClass:string,
	duration?:number,
}
class CSSTransition {

	config:CSSTransitionConfig;

	constructor( config:CSSTransitionConfig ) {
		this.config = config;
	}

	start():Promise<void> {
		

		// apply transition class
		this.config.target.addClass( this.config.cssClass );
		
		// check duration
		var duration = this.config.duration;
		if( duration ) {
			this.config.target.style.transitionDuration = ( duration / 1000 ) + 's';
		} else {
			duration = this.parseDuration();
		}

		// force css transition apply
		this.config.target.native.offsetHeight;


		return new Promise<void>( function( resolve, reject ) {
			setTimeout( resolve, duration );
		}).then( this.cleanUp.bind( this ) );
	}

	cleanUp() {
		this.config.target.removeClass( this.config.cssClass );
		this.config.target.style.transitionDuration = '';
	}

	parseDuration() {
		return parseFloat( this.config.target.css( 'transition-duration' ) ) * 1000;
	}
}

export default CSSTransition;