// import lib
import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import ListRenderer from 'ln/list/ListRenderer';
import List from 'ln/list/List';
import View from 'ln/view/View';
import Model from 'ln/model/Model';
import DragDropManager from 'ln/../ui/dragdrop/DragDrop';
// import project
import Question from '../Question/Question';
import DragDropClozeModel from '../DragDropCloze/DragDropClozeModel';
import SlideRenderer from '../../sliderenderer/SlideRenderer';
import DragDrop from '../DragDrop/DragDrop';
import { Drag, Drop } from '../DragDrop/DragDropModel';

/**
 * A slide that shows a cloze with drag and drop functionality
 * 
 * See Cloze for an Example. The cloze gaps get transformed in the DragDropClozeModel
 * to set the expected drags and drops for the DragDropModel
 * 
 */

class DragDropCloze extends DragDrop {

	public model: DragDropClozeModel;
	public additionalDropSpace: number;
	

	constructor( model: DragDropClozeModel, slideRenderer: SlideRenderer ) {

		super( model, slideRenderer );
		this.defaultTemplate = this.model.get( 'template', 'lf.dragdrop-cloze-slide' );
		this.dragTemplate = this.model.get( 'dragTemplate', 'lf.dragdrop-drag-cloze-item' );
		this.dropTemplate = this.model.get( 'dropTemplate', 'lf.dragdrop-drop-cloze-item' );
		this.additionalDropSpace = this.model.get('additionalDropSpace', 2);
		this.maxDragsOnDrop = this.model.get( 'maxDragsOnDrop', 1 );
	}

	protected renderDrops() {

		var c = this.node.js( 'text' );
		var template: string = c.toString();
		
		for (var i = 0; i < this.model.drops.length; i++) { 
			var dropTarget = Node.fromHTML( Template.render(this.dropTemplate, this.model.drops[i]) );
			template = template.replace("{" + this.model.getGapLabels()[i] + "}", dropTarget.toString());
		};

		c.html = template;

	}

	protected init() {
		super.init();

		//if gapLength is set to 0, set the drop width style automatically
		this.slideRenderer.slideChanged.addOnce( () => {
			if (this.model.get('gapLength') == "0") {
				var longestItem = this.node.all('[js=drag-node]').reduce( function (a, b) {
					return a.bounds().width > b.bounds().width ? a : b;
				});
				var spacing =  parseInt(window.getComputedStyle( longestItem.native ).marginLeft, 10) + parseInt(window.getComputedStyle( longestItem.native ).marginRight, 10) ;
				var totalSpace = longestItem.bounds().width + spacing;
			}
			else {
				var totalSpace = this.model.get('gapLength') as number;
			}

			this.node.all('[js=drop-node]').forEach( (node:Node) => {
				node.style.width = totalSpace + this.additionalDropSpace + 'px';
			});
		});
	}

	/**
	 * Move the drags to the drop targets according 'dropped' values of the model
	 */
	protected initDropPositions() {
		this.model.drags.forEach(( drag ) => {
			if ( drag.dropped != undefined && drag.dropped != 0 ) {
				var dragLink = this.dragListRenderer.linkOf( drag );
				var dropNode = this.node.one( '[data-index="' + (drag.dropped) + '"]' ).parent();
				dropNode.js( 'drop-target' ).append( dragLink.node );
			}
		});
	}

   	/**
	 * Returns the number of drags on a drop node
	 */
	protected dragsOnDrop( drop: Drop ) {

		var dropNode = Node.one( '[data-index="' + (drop.target) + '"]' ).parent();
		var drags = dropNode.all( '[js=drag-node]' );
		return drags.length;
	}

	protected removeFirstDrag( drop: Drop ) {
		
		var dropNode = Node.one( '[data-index="' + (drop.target) + '"]' ).parent();

		if ( dropNode.all( '[js=drag-node]' ).length > 0 ) {
			// get the first node
			var dragNode = dropNode.all( '[js=drag-node]' )[0];
			// remove it from target
			dropNode.js( 'drop-target' ).removeChild( dragNode );
			// move it to the initial position 
			this.dragContainer.append( dragNode );

			this.dragListRenderer.linkOf( dragNode ).item.dropped = 0;
		}

	}

	public handleClicks(){
		this.dragListRenderer.links.all().forEach( drag => {
			drag.node.click.add( ( n, e ) => {
				// in case we have a currentDrag allready
				if( this.activeDrag ) this.unsetActiveDrag();
				this.activeDrag = n;
				this.activeDrag.addClass( '-clicked' );
				e.stopPropagation();
			});
		});

	}
}

export default DragDropCloze;