import Node from 'ln/node/Node';
import Template from 'ln/template/TemplateManager';
import setup, { SetupConfig } from 'ln/setup/setup';

import { scanner } from 'ln/view/Scanner';
import "lnui/collapse/Collapse";
import Autocomplete from "./views/Autocomplete";
import Moment from 'Moment';
import Pikaday from 'Pikaday';
import * as clipboard  from "clipboard-polyfill/clipboard-polyfill";


import mapper from './setup/mapper';
import './templates/heftreihe';
import './templates/elements';
import './templates/lernfragen';
import './templates/slides';

import StartPage from './views/StartPage';
import MagazinePage from './views/MagazinePage';
import PreviewPage from './views/PreviewPage';
import TaskEditorPage from './views/TaskEditorPage';
import UnitPage from './views/UnitPage';
import SinglePage from './views/SinglePage';


export function init( config:SetupConfig ){
    Template.context.setup = setup;
    Template.context.image = function( image:{ file_name:string }, preset:string = 'medium', ext:string = 'png' ){
		return decodeURIComponent( setup.route( 'asset', { file_name:  image.file_name, preset:preset, ext:ext } ).url() );
    }
    
    Template.context.publicImagePath = function( file_name:string ){
		return decodeURIComponent( setup.route( 'publicImagePath',  {file_name: file_name}  ).url() );
    }

    Template.context.download = function( document:{ file_name:string } ) {
		return decodeURIComponent( setup.route( 'download',  {file_name: document.file_name}  ).url() ); 
    }

    if (config) {
        setup.init( config );
    }

    // Mobile-Menu Toggler
    let mobileNavigation = Node.js( 'navigation-link');
    if (mobileNavigation) {
        mobileNavigation.click.add( function(){
            Node.js('mobilenavigation-menu').toggleClass('hidden');
            Node.js('navigation-link').toggleClass('-open');
        });

        //close menu if clicked outside
        const outsideClickListener = event => {
            let menu = Node.js('mobilenavigation-menu')
            var menuNative = menu.native;
            if (!menuNative.contains(event.target) && !mobileNavigation.native.contains(event.target) ) { // or use: event.target.closest(selector) === null
                menu.addClass('hidden');
            }
        }
        document.addEventListener('click', outsideClickListener)
    }

}


export function startpage( ) {
    var data = mapper.model(setup.data('startpage'));
    new StartPage(data).render(Node.one('#startpage'));
    scanner.scan( Node.fromNative( document.body ) );
}

export function preview( ) {
    var magazine = mapper.model(setup.data('magazine'));
    new PreviewPage(magazine).render(Node.one('#preview'));
    scanner.scan( Node.fromNative( document.body ) );
    scrollmonitor();
}


export function magazine( ) {
    var data = mapper.model(setup.data('magazine'));
    new MagazinePage(data).render(Node.one('#magazine'));
    scanner.scan( Node.fromNative( document.body ) );
    Node.one('.task-container').addClass('hidden');
    scrollmonitor();
}

export function taskeditor( ) {
    var data = mapper.model(setup.data('taskeditor'));
    new TaskEditorPage(data).render(Node.one('#taskeditor'));
    scanner.scan( Node.fromNative( document.body ) );
    Node.one('.magazine-description').addClass('hidden');
    Node.one('.magazine-header').addClass('hidden');
    Node.one('.preview-switch').addClass('hidden');
}


export function unit( ) {
    var data = mapper.model(setup.data('unit'));
    new UnitPage(data).render(Node.one('#unit'));
    scanner.scan( Node.fromNative( document.body ) );
}

export function singlepage(  ) {
    var data = mapper.model(setup.data('singlepage'));
    new SinglePage(data).render(Node.one('#singlepage'));
    scanner.scan( Node.fromNative( document.body ) );
}

export function license() {
    Node.one('.add-license').all('.input').forEach( (input)=> {
        //change input field if there are 4 characters in it
        input.keyup.add( (node, event) => {
            let ignoredKeys = ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', 'Backspace', 'Tab', 'Shift' ]; //TODO: there is probably more...
            if ( ignoredKeys.indexOf( event.key ) >= 0) {
                return;
            }
            let name = node.getAttribute('name');
            let position = parseInt( name[name.length -1] );
            
            if (position !== 4 && node.value.length === 4) {
                let nextField = '[name="value' + (position + 1) + '"]';
                Node.one( nextField ).native.focus();
            }
        });

        //paste in complete license if there is a licensekey in the clipboard
        input.native.addEventListener('paste', (e)=> {
            let clipboardData = e.clipboardData || window.clipboardData;
            let pastedData = clipboardData.getData('Text');
            pastedData.replace(/ /g,'');
            let pastedArray = pastedData.split('-');
            if (pastedArray.length === 4 && pastedData.length === 19) {
                e.preventDefault();
                Node.one('[name="value1"]').value = pastedArray[0];
                Node.one('[name="value2"]').value = pastedArray[1];
                Node.one('[name="value3"]').value = pastedArray[2];
                Node.one('[name="value4"]').value = pastedArray[3];
                Node.one('[type="submit"]').native.focus();
            }
        }); 
    });
}


export function autocomplete( config ) {
    if (config) {
        setup.init( config );
    }

    scanner.ioc.add( 'autocomplete', function( node:Node ) {
        return new Autocomplete( ).render( node );
    });
    
    scanner.scan( Node.fromNative( document.body ) );
}

export function daypicker( config ) {

    if (config) {
        setup.init( config );
    }

    scanner.ioc.add( 'daypicker', function( node:Node ) {
        return new Pikaday( {
            field:node.native, 
            format: 'DD.MM.YYYY',
            setDefaultDate: false,
            firstDay: 1,
        });
    });
    
    scanner.scan( Node.fromNative( document.body ) );

}



export function copy2clipboard( config ) {

    if (config) {
        setup.init( config );
    }

    scanner.ioc.add( 'copy2clipboard', function( node:Node ) {
        node.js('copy2clipboard').click.add( ()=>  {
            let result = clipboard.writeText( node.getAttribute('data-copy') );
            let msg = '';
    
            var isiOSDevice = navigator.userAgent.match(/ipad|iphone/i);
            if (isiOSDevice) {
                msg = 'Ihr Betriebssystem unterstützt leider kein automatisches Kopieren in die Zwischenablage. Sie haben die Informationen aber per Mail erhalten';
            }
            else {
                msg = 'Das Element ' + node.getAttribute('data-copy') +' wurde in Ihre Zwischenablage kopiert ';
            }
    
            let copyLinkOverlay = Node.fromHTML( Template.render( 'hr.task-alert', {message: msg } ));
            copyLinkOverlay.js('ok').click.add( ()=> {
                Node.one('.editor-content').js('alert').remove();
            })
            Node.one('.editor-content').prepend(copyLinkOverlay);
            window.scrollTo(0, 0);
        })
        

    });
    
    scanner.scan( Node.fromNative( document.body ) );

}


export function scrollmonitor() {
    Node.one('#navigation-main').addClass('-transparent');
    window.addEventListener('scroll', function(e) {
        var width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        //different height of cover image depending on viewport
        //let scrollThreshold = ( width >= 768 ) ? 389 : 230;
        let scrollThreshold = 10;
        if (window.pageYOffset > scrollThreshold) {
            Node.one('#navigation-main').removeClass('-transparent');
            //Node.one('.cover-image >.transparent').addClass('hidden');
        }
        else {
            Node.one('#navigation-main').addClass('-transparent');
            //Node.one('.cover-image >.transparent').removeClass('hidden');
        }
    });
}